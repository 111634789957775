function loadFaq() {

    $(".faq-question").on("click", function(e){
        e.preventDefault();

        if($(this).parent().hasClass("collapsed"))
        {
            $(this).parent().removeClass("collapsed");
        }else{
            $(this).parent().addClass("collapsed");
        }


    });

}

module.exports = loadFaq;