require("../vendor/bxslider/v4.1.2/jquery.bxslider.min");

function bxSlider(){

    //Sponsor slider
    if ($(".sponsors li").length > 1) {
        $(".sponsors").bxSlider({
            auto: true,
            autoControls: false,
            mode:'horizontal',
            pagerCustom: '.bx-pager',
            captions: false,
            controls: false,
            responsive: true,
            pause: 3000,
            minSlides: 2,
            maxSlides: 2,
            slideWidth: 135,
            preventDefaultSwipeY: false,
            touchEnabled: false,
            onSlideBefore: function(){
                $('.bx-clone').css('display', 'block');
            }
        });
    }
}

module.exports = bxSlider;