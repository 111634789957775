//Require
window.$ = window.jQuery = require("./vendor/jquery/v1.11.3/jquery-1.11.3.min");
var bootstrapValidator = require("./src/bootstrapvalidator");
var bxSlider = require("./src/bxslider");
var mobileMenu = require("./src/mobilemenu");
var modernizr = require("./src/modernizr");
var magnific_popup = require("./src/magnific_popup");
var loadFaq = require("./src/faq");
var loadGastenboek = require("./src/gastenboek");
//var loadHTMLparts = require("./src/loadhtmlparts");

//Execute scripts
modernizr.checkSupportsvg();
modernizr.checkDevices();
mobileMenu();
magnific_popup();
bxSlider();
bootstrapValidator();
loadFaq();
loadGastenboek();

//loadHTMLparts();