require("../vendor/magnific_popup/v0.9.9/magnific-popup-0.9.9.min.js")


function magnific_popup(){

    /* Gallery */
    $('.photo-item').magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: 'Afbeelding laden #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1], // Will preload 0 - before current, and 1 after the current image
            tPrev: 'Vorige', // title for left button
            tNext: 'Volgende', // title for right button
            tCounter: '<span class="mfp-counter">%curr% / %total%</span>'
        },
        image: {
            tError: '<a href="%url%">Afbeelding #%curr%</a> kon niet worden geladen.',
            titleSrc: function(item) {
                return item.el.attr('title');
            }
        }
    });


    /* Youtube Movie */
    $('.popup-youtube').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,

        fixedContentPos: false
    });

}

module.exports = magnific_popup;