function loadGastenboek() {

    if($("#val1").length >= 1)
    {
        val1 = $("#val1").val();
        val2 = val1 * 3;
        $("#val2").val(val2);
    }

}

module.exports = loadGastenboek;
